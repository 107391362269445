<script setup lang="ts">
import { getHyphenatedCurrentLocale } from '@@/bits/current_locale'
import device from '@@/bits/device'
import { __ } from '@@/bits/intl'
import { getLocaleName } from '@@/bits/locale'
import { navigateTo, reload } from '@@/bits/location'
import OzMenuRows, { OzMenuRowsFocusStyle, OzMenuRowsSizePreset } from '@@/library/v4/components/OzMenuRows.vue'
import { OzBoxColors } from '@@/library/v4/components/OzPopoverModal.vue'
import OzScreenSizeAwarePopoverModal from '@@/library/v4/components/OzScreenSizeAwarePopoverModal.vue'
import { useWindowSizeStore } from '@@/pinia/window_size'
import LocaleMenu from '@@/vuecomponents/LocaleMenu.vue'
import { useLocale } from '@@/vuecomposables/locale'
import { storeToRefs } from 'pinia'
import { computed, ref } from 'vue'

enum AuthActionType {
  Help = 'Help',
  Accessibility = 'Accessibility',
  LocaleMenu = 'LocaleMenu',
}

const HELP_URL = 'https://padlet.help'
const ACCESSIBILITY_URL = 'https://padlet.com/about/accessibility'

const windowSizeStore = useWindowSizeStore()
const { isSmallerThanTabletPortrait, windowHeight } = storeToRefs(windowSizeStore)

const { changeLocale } = useLocale()

const isSelectorOpen = ref(false)
const currentLocale = ref(getHyphenatedCurrentLocale())

const emit = defineEmits(['cancel'])

const currentLocaleName = computed(() => getLocaleName(currentLocale.value))

const menuActions = computed(() => {
  return [
    {
      text: __('Help'),
      eventPayload: AuthActionType.Help,
      href: HELP_URL,
    },
    {
      text: __('Accessibility'),
      eventPayload: AuthActionType.Accessibility,
      href: ACCESSIBILITY_URL,
    },
    {
      text: __('Language'),
      eventPayload: AuthActionType.LocaleMenu,
      rightIcon: 'chevron_right_center',
      rightIconSize: 16,
      rightLabel: currentLocaleName.value,
      testId: 'localeMenuButtonActionMenu',
      ariaLabel: __('Open language selector'),
      ariaDescription: __('Selecting a different language will reload the page.'),
    },
  ]
})

const isPhone = computed((): boolean => {
  return device.phone || isSmallerThanTabletPortrait.value
})

const openLocaleMenu = (): void => {
  isSelectorOpen.value = true
}

const closeLocaleMenu = (): void => {
  isSelectorOpen.value = false
}

const handleChangeLocale = async (lang: string): Promise<void> => {
  isSelectorOpen.value = false

  if (currentLocale.value === lang) return

  currentLocale.value = lang
  await changeLocale(lang)

  reload()
}

const onSelectMenuRow = (_event, eventPayload: AuthActionType): void => {
  switch (eventPayload) {
    case AuthActionType.Help:
      navigateTo(HELP_URL, { target: '_blank' })
      break
    case AuthActionType.Accessibility:
      navigateTo(ACCESSIBILITY_URL, { target: '_blank' })
      break
    case AuthActionType.LocaleMenu:
      openLocaleMenu()
  }
}

const cancel = (): void => {
  closeLocaleMenu()
  emit('cancel')
}
</script>

<template>
  <div>
    <OzScreenSizeAwarePopoverModal
      role="dialog"
      :aria-label="__('Open more menu dialog')"
      :is-phone="isPhone"
      :is-draggable-drawer="true"
      :window-height="windowHeight"
      :disable-drag="true"
      :preferred-tray-start-height="274"
      :x-popover-tip="false"
      :popover-width="304"
      :popover-anchor="{}"
      :color="OzBoxColors.PrimaryBlur"
      :box-classes="['top-12 end-3 end-auto']"
      @scrim-click="$emit('cancel')"
      @scrim-esc="$emit('cancel')"
    >
      <template #body>
        <div class="py-2" :class="isPhone && 'box-border min-h-[274px]'">
          <OzMenuRows
            :is-phone="isPhone"
            :focus-style="OzMenuRowsFocusStyle.Bubble200"
            :size-preset="OzMenuRowsSizePreset.H48px"
            :rows="menuActions"
            @select-row="onSelectMenuRow"
          />
        </div>
      </template>
    </OzScreenSizeAwarePopoverModal>

    <LocaleMenu
      v-if="isSelectorOpen"
      :current-locale="currentLocale"
      :is-phone="isPhone"
      :width="304"
      :box-classes="['top-12 end-3 rtl:end-4 end-auto', 'h-[320px]']"
      :window-height="windowHeight"
      :preferred-tray-start-height="352"
      @back="closeLocaleMenu"
      @cancel="cancel"
      @change="handleChangeLocale"
    />
  </div>
</template>
